








































































import { Component, Vue } from "vue-property-decorator";
import { AppUser } from "@/models/user";
import { ApiEndPoints } from "@/helpers/api_endpoints";
import _axios from "@/plugins/axios";
import agent from "@/helpers/agent";
import Audit from "@/models/audit";

@Component
export default class OrganizationsView extends Vue {
  private logs: Audit[] = [];
  pagination = {
    sortBy: "DateTime",
    descending: true,
    page: 0,
    rowsPerPage: 10,
    rowsNumber: 10,
  };
  scrollSettings = {
    suppressScrollY: false,
    suppressScrollX: false,
    wheelPropagation: false,
  };
  loading = true;
  filter = "";
  columns = [
    {
      name: "UserId",
      required: true,
      label: "UserId",
      align: "left",
      field: (row: Audit) => row.user?.fullName,
      sortable: true,
      classes: "bg-grey-2 ellipsis",
      headerClasses: "bg-primary text-white",
    },
    {
      name: "TableName",
      required: true,
      label: "TableName",
      align: "left",
      field: (row: Audit) => row.tableName,
      sortable: true,
    },
    {
      name: "Type",
      required: true,
      label: "Type",
      align: "left",
      field: (row: Audit) => row.type,
      format: (val: string) => `${val}`,
      sortable: false,
    },
    // {
    //   name: "NewValues",
    //   required: true,
    //   label: "NewValues",
    //   align: "left",
    //   field: (row: Audit) => this.GetValuesText(row),
    //   format: (val: string) => `${val}`,
    //   sortable: false,
    // },
    {
      name: "actions",
      required: true,
      label: "Actions",
      align: "right",
      field: "Actions",
    },
  ];

  GetValuesText(audit: Audit) {
    const oldValue = JSON.parse(audit.oldValues);
    const newValue = JSON.parse(audit.newValues);

    if (oldValue && newValue) {
      const values = Object.keys(oldValue)
        .filter((x) => oldValue[x] !== newValue[x])
        .map((x: any) => {
          return `<li> changed ${x} from <span class="text-weight-bold">${oldValue[x]}</span> to <span class="text-weight-bold">${oldValue[x]}</span></li>`;
        });
      return `${values}`.replaceAll(",", "");
    }

    if (newValue) {
      const values = Object.keys(newValue)
        .filter((x) => ![undefined, null, NaN, ""].includes(newValue[x]))
        .map((x) => {
          return { field: x, value: newValue[x] };
        });
      return `
      ${values.map((x) => {
        return `<li>set ${x.field} to <span class="text-weight-bold">${x.value}</span></li>`;
      })}
      `.replaceAll(",", "");
    }
    return;
  }
  showPKValues(audit: Audit) {
    return audit.type !== "Create";
  }
  primaryKey(audit: Audit) {
    console.log(this.GetValuesText(audit));
    const pkObj = JSON.parse(audit.primaryKey);
    return pkObj[Object.keys(pkObj)[0]];
  }
  lockedIcon(row: AppUser): string {
    return row.lockoutEnd === null ? "lock" : "lock_open";
  }

  mounted() {
    this.onRequest({
      pagination: this.pagination,
      filter: "",
    });
  }
  onRequest(props: any = {}) {
    const { page, rowsPerPage, sortBy, descending } = props.pagination;
    const search = props.filter;
    this.loading = true;
    // get all rows if "All" (0) is selected
    const size = rowsPerPage === 0 ? this.pagination.rowsNumber : rowsPerPage;
    agent.Logs.list(search, descending, page, size, sortBy).then(
      (rows) => {
        //populate table
        this.logs = rows.rows;
        this.pagination.rowsNumber = rows.rowCount;

        // don't forget to update local pagination object
        this.pagination.page = page;
        this.pagination.rowsPerPage = rowsPerPage;
        this.pagination.sortBy = sortBy;
        this.pagination.descending = descending;

        // ...and turn of loading indicator
        this.loading = false;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  goToUpdate(id: string) {
    this.$router.push({
      name: "FormFinancialinstitution",
      query: { id },
    });
  }
  exportData() {
    const token = this.$store.getters["app/getToken"];
    return _axios
      .get(`api/${ApiEndPoints.financialInstitutions}/export-to-excel`, {
        responseType: "blob",
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/octet-stream",
          })
        );
        const fileLink = document.createElement("a");
        fileLink.href = url;
        fileLink.download = "Logs.xlsx";
        document.body.appendChild(fileLink);
        fileLink.click();
      });
  }
}
